import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";

import { Text, Box, Container } from "theme-ui";

export interface HomeProps {
  data?: any;
}

const Testimonials: React.FC<HomeProps> = ({ data }) => {
  const postEdges = (data && data.caseStudies && data.caseStudies.edges) || [];

  return (
    <Layout>
      <Plate variant="styles.contentFrame">
        <Container>
          <Plate variant="styles.contentFrame2">
            <Text as="h1" variant="pageTitle">
              Testimonials
            </Text>
            <Box sx={{ py: 5}}>
              <Text as="p" variant="sectionBody">
                "I first heard of CCS when I was struggling to
                find an OT facility to conduct a patient's surgery at short
                notice. Since then we have completed quite a few complex surgeries
                at this center. What makes CCS stand apart is the ease of
                scheduling surgeries on Saturdays and Sundays unlike regular
                hospitals" </Text>
              <Text as="p" sx={{ fontSize: 2, mt: 2}}>Dr Surij Salih </Text>
            </Box>
            <Text as="p" variant="sectionBody">
              "As an anaesthetist, it is imperative that
              I have a competent support staff for smooth administration of my
              procedures and the team I came across at CCS couldn't have been
              better. Infrastructure-wise, it is very encouraging to see
              state-of-the-art equipment and amenities that support
              anaesthetists in the pre and post-operative phases of surgery"
            </Text>
            <Text as="p" variant="sectionBody">
              "We have gone to renowned hospitals
              for surgery before but the personalised care and attention we
              received at CCS is unmatched. The nurses show true
              professionalism, tending to and monitoring my wife continuously
              for any signs of distress."
              <Text as="p" sx={{ fontSize: 2, mt: 2}}>Aravindan (Patient's husband) </Text>
              
              "Surgeons were a call away and the
              staff made me feel like I was recovering at home post-operation. I
              was nursed to health in a matter of three days and my family
              couldn't be happier that we chose CCS for my surgery."</Text>

              <Text as="p" sx={{ fontSize: 2, mt: 2}}>Dr Sunsheen</Text>
            
          </Plate>
        </Container>
      </Plate>
    </Layout>
  );
};

export default Testimonials;
